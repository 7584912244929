export default function SecondaryHeader({ globeImage, primaryText, subText }) {
	return (
		<div className="relative h-40 w-full bg-bg-primary2">
			<img
				src={globeImage}
				alt="GlobeImage"
				className="float-right mr-24 h-full bg-contain"
			/>
			<div className="absolute bottom-10 ml-6">
				<h1 className="text-2xl font-semibold text-slate-950">{primaryText}</h1>
				<p className="text-gray-700">{subText}</p>
			</div>
		</div>
	);
}
