import { Link } from "react-router-dom";
import exportIcon from "./icons/export.svg";

export default function UseCaseCard({ img, tag, title, path }) {
	return (
		<div className="flex h-96 w-full flex-col rounded border border-gray-1">
			<img
				src={img}
				alt={`${title}`}
				className="left-0 h-52 w-auto rounded rounded-b-none border"
			/>
			<div className="relative flex-1 p-4">
				<p className="inline-flex rounded-sm bg-tint-20 px-1">{tag}</p>
				<p
					className="mt-3 line-clamp-2 px-1 font-semibold"
					data-te-toggle="tooltip"
					data-te-placement="bottom"
					data-te-ripple-init
					data-te-ripple-color="light"
					title={`${title}`}
				>
					{title}
				</p>
				<Link
					to={path}
					target="_blank"
					className="
                absolute bottom-4 flex flex-row gap-2 rounded border border-tint-20 px-4 py-1.5 font-semibold
                hover:cursor-pointer hover:bg-tint-20"
				>
					<p>View use case</p>
					<img src={exportIcon} alt="open in new tab" />
				</Link>
			</div>
		</div>
	);
}
