import { useState } from "react";

export default function useSearchBox(arr) {
	const [value, setValue] = useState("");
	function handleChange(newValue) {
		setValue(newValue);
	}
	function getData() {
		arr = arr.filter((card) =>
			card.title.toLowerCase().includes(value.toLowerCase()),
		);
		return arr;
	}
	return [value, handleChange, getData];
}
