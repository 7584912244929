import IframeViewer from "../../../components/IframeViewer";

export default function ReconcilliationPage() {
	return (
		<IframeViewer
			title={"Reconcilliation"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=122a1f6c-525f-43bb-a717-991600bbf2f0&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
