import { useContext } from "react";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import useSearchBox from "../../hooks/useSearchBox";
import { image1 } from "./selfServiceAnalyticsThumnails";
import ModalCardListing from "../../components/ModalCard/ModalCardListing";

export default function SelfServiceAnalyticsPage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title: "Exploring Scout Platform via PowerBI",
			tag: "Self Service Analytics",
			coverImage: image1,
			url: "/ssa/01",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb homepageName="Homepage" pageName="Self Service Analytics" />
			<SuccessStoriesBanner
				bannerTitle="Platform Analytics"
				value={value}
				onChange={handleChange}
			/>
			<ModalCardListing cards={getData()} />
		</div>
	);
}
