import backwardArrowIcom from "./icons/mdi_arrow_Backward.svg";
import rightArrowIcon from "./icons/arrow_right.svg";
import { Link } from "react-router-dom";

export default function BreadCrumb({ pageName, homepageName = "Industries" }) {
	return (
		<div className="h-12 w-full bg-white drop-shadow-md">
			<div className="ml-2 flex flex-row gap-1 p-3">
				<Link to="/" className="flex cursor-pointer flex-row gap-2">
					<img
						src={backwardArrowIcom}
						alt="Go Back"
						className=" mt-0.5 border border-white hover:border hover:bg-gray-100"
					/>
					<p className="font-normal hover:text-link-blue">{homepageName}</p>
				</Link>
				<img src={rightArrowIcon} alt="right-arrow" />
				<p className=" font-semibold">{pageName}</p>
			</div>
		</div>
	);
}
