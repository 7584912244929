import { Route, Routes } from "react-router-dom";
import SCMProcuremtPage from "./UseCases/SCMProcuremtPage";
import ManufacturingPage from "./ManufacturingPage";

export default function ManufacturingRoutes() {
	return (
		<Routes>
			<Route path="/manufacturing">
				<Route index element={<ManufacturingPage />} />
				<Route path="02-scm" element={<SCMProcuremtPage />} />
			</Route>
		</Routes>
	);
}
