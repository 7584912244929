import IframeViewer from "../../../components/IframeViewer";

export default function DrivingPaymentCompliancePage() {
	return (
		<IframeViewer
			title={"Driving Page Compliance"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=92d3bb15-e984-4929-9023-ac1b5c1e7975&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
