import IframeViewer from "../../../components/IframeViewer";

export default function COISBillingPage() {
	return (
		<IframeViewer
			title={"Cost of Unintegrated Systems Billing"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=3f5586a5-bba9-45ea-8a48-24888a7fdb49&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
