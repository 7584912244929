import { Route, Routes } from "react-router-dom";
import BFSIPage from "./BfsiPage";
import WealthManagementPage from "./UseCases/WealthManagementPage";
import ReconcilliationPage from "./UseCases/ReconcilliationPage";
import Underwriting from "./UseCases/UnderwritingPage";
import CustomerOnboarding from "./UseCases/CustomerOnboardingPage";

export default function BFSIRoutes() {
	return (
		<Routes>
			<Route path="/bfsi">
				<Route index element={<BFSIPage />} />
				<Route path="07-wealth-management" element={<WealthManagementPage />} />
				<Route path="08-reconciliation" element={<ReconcilliationPage />} />
				<Route
					path="11-underwriting-mortgage-firm"
					element={<Underwriting />}
				/>
				<Route
					path="12-customer-onboarding-in-mortgage-firm"
					element={<CustomerOnboarding />}
				/>
			</Route>
		</Routes>
	);
}
