import { useEffect } from "react";

export default function IframeViewer({ title, src }) {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<iframe
			title={title}
			src={src}
			className="h-screen w-screen border-none"
			allowFullScreen={true}
		></iframe>
	);
}
