import IframeViewer from "../../../components/IframeViewer";

export default function SCMProcuremtPage() {
	return (
		<IframeViewer
			title={"SCM Procurement"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=a8fbbaa9-9d78-4ce4-8357-ee1be9a4f96c&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
