import IframeViewer from "../../../components/IframeViewer";

export default function ApplicationOptimizationPage() {
	return (
		<IframeViewer
			title={"Application Portfolio Rationalization"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=b778f4c0-979c-42ec-b235-a260aa40cd7c&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
