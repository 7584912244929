import IframeViewer from "../../../components/IframeViewer";

export default function Underwriting() {
	return (
		<IframeViewer
			title={"Underwriting"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=41e407b1-07fc-4654-98e8-592e942a13b1&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
