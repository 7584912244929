import IframeViewer from "../../../components/IframeViewer";

export default function DisconnectionDebtPage() {
	return (
		<IframeViewer
			title={"Disconnection Debt"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=14be25fd-7f72-4e52-8029-bdbe317942ec&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
