import IframeViewer from "../../../components/IframeViewer";

export default function DrivingSTPPage() {
	return (
		<IframeViewer
			title={"Drive Increase in Straight through Processing (STP) for orders"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=1be2df7e-215b-4004-bb02-4fb9b604a351&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
