import IframeViewer from "../../../components/IframeViewer";

export default function COISPage() {
	return (
		<IframeViewer
			title={"Cost of Unintegrated Systems"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=b4e99aad-b166-433a-a20b-78284b034635&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
