import IframeViewer from "../../../components/IframeViewer";

export default function OptimizingDataUtilizationPage() {
	return (
		<IframeViewer
			title={"Optimizing Data Utilization"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=a20395dc-0274-4097-afbb-7f28b0a312da&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
