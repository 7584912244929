import { image1 } from "./manufacturingThumbnails/index";
import { useContext } from "react";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";
import useSearchBox from "../../hooks/useSearchBox";

export default function ManufacturingPage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title:
				"Transforming Supply Chain Operations at a Construction & Engineering Company",
			tag: "Supply Chain",
			coverImage: image1,
			url: "/manufacturing/02-scm",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Manufacturing" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
