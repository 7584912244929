import IframeViewer from "../../../components/IframeViewer";

export default function DrivingFasterMasterDataCreationPage() {
	return (
		<IframeViewer
			title={"Driving faster Master Data Creation"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=c312705c-650d-4bda-9f02-bcbafbb2e365&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
