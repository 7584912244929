import { image1, image2 } from "./FoodandBeverageThumbnails/index";
import { useContext } from "react";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";
import useSearchBox from "../../hooks/useSearchBox";

export default function FoodandBeveragePage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title: "Dialling Up Customer Experience in a Fortune 500 Beverage Maker ",
			tag: "Order Management",
			coverImage: image1,
			url: "/food-and-beverage/05-order-management",
		},
		{
			id: 2,
			title: "Drive Increase in Straight through Processing (STP) for orders",
			tag: "Order Fullfillment",
			coverImage: image2,
			url: "/food-and-beverage/02-driving-stp",
		},
	];
	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Food & Beverage" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
