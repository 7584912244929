import { MsalContext } from "@azure/msal-react";
import { useContext } from "react";
import { image1, image2, image3, image4, image5 } from "./uucThumbnails";
import useSearchBox from "../../hooks/useSearchBox";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";

export default function UUCPage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title: "Application Portfolio Rationalization",
			tag: "Organization Wide",
			coverImage: image1,
			url: "/uuc/01-uuc-application-optimization",
		},
		{
			id: 2,
			title: "Cost of Unintegrated Systems",
			tag: "Organization Wide",
			coverImage: image2,
			url: "/uuc/02-uuc-cois",
		},
		{
			id: 3,
			title: "Cost of Unintegrated Systems - Billing",
			tag: "Organization Wide",
			coverImage: image3,
			url: "/uuc/03-uuc-cois-billing",
		},
		{
			id: 4,
			title: "Cost of Unintegrated Systems - Procurement",
			tag: "Organization Wide",
			coverImage: image4,
			url: "/uuc/04-uuc-cois-procurement",
		},
		{
			id: 5,
			title: "Optimize Data Capture",
			tag: "Organization Wide",
			coverImage: image5,
			url: "/uuc/05-data-capture-efficiciency-claims",
		},
		{
			id: 6,
			title: "Platform - Sand",
			tag: "Organization Wide",
			coverImage: image1,
			url: "/uuc/06-platform-sand",
		},
		{
			id: 7,
			title: "Platform - Outlook",
			tag: "Organization Wide",
			coverImage: image2,
			url: "/uuc/07-platform-outlook",
		},
		{
			id: 8,
			title: "Platform - Documents",
			tag: "Organization Wide",
			coverImage: image3,
			url: "/uuc/08-platform-documents",
		},
		{
			id: 9,
			title: "Platform - Work Dynamics",
			tag: "Organization Wide",
			coverImage: image4,
			url: "/uuc/09-platform-work-dynamics",
		},
		{
			id: 10,
			title: "IT Use Case",
			tag: "Organization Wide",
			coverImage: image1,
			url: "/uuc/10-it-use-case",
		},
		{
			id: 11,
			title: "Disconnection Debt",
			tag: "Organization Wide",
			coverImage: image2,
			url: "/uuc/11-disconnection-debt",
		},
		{
			id: 12,
			title: "Optimizing Data Utilization",
			tag: "Organization Wide",
			coverImage: image3,
			url: "/uuc/12-optimizing-data-utilization",
		},
		{
			id: 13,
			title: "SAP : Visibility and Optimization",
			tag: "Organization Wide",
			coverImage: image5,
			url: "/uuc/13-sap-visibility-and-optimization",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Universal Use Case" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
