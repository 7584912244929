import { useContext } from "react";
import { image1, image2 } from "./uucThumbnails";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";
import useSearchBox from "../../hooks/useSearchBox";

export default function EnergyPage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title: "Driving Payment Compliance",
			tag: "Oil & Gas",
			coverImage: image1,
			url: "/energy/01-driving-payment-compliance",
		},
		{
			id: 2,
			title: "Driving faster Master Data Creation",
			tag: "Oil & Gas",
			coverImage: image2,
			url: "/energy/02-driving-faster-master-data-creation",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Energy" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
