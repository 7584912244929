import { Route, Routes } from "react-router-dom";
import ConstructionPage from "./ConstructionPage";
import PayrollPage from "./UseCases/PayrollPage";

export default function ConstructionRoutes() {
	return (
		<Routes>
			<Route path="/construction">
				<Route index element={<ConstructionPage />} />
				<Route path="03-payroll-processing" element={<PayrollPage />} />
			</Route>
		</Routes>
	);
}
