import IframeViewer from "../../../components/IframeViewer";

export default function PlatformOutlookPage() {
	return (
		<IframeViewer
			title={"Platform - Outlook"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=6b21da61-5c52-48bd-814c-afb7869b58a5&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
