import IframeViewer from "../../../components/IframeViewer";

export default function CustomerOnboarding() {
	return (
		<IframeViewer
			title={"Customer Onboarding"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=24bafd6a-474e-4402-b19e-6d4c792e05a5&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
