export default function SuccessStoriesBanner({
	value,
	onChange,
	bannerTitle = "Success Stories",
}) {
	const showSearch = false; //Show a search bar by toggling this to true
	return (
		<div className="flex flex-row justify-between border-b border-gray-1 p-3">
			<h2 className="ml-3 font-semibold">{bannerTitle}</h2>
			{showSearch ? (
				<input
					value={value}
					onChange={(e) => onChange(e.target.value)}
					id="search"
					type="text"
					placeholder="Search"
					className="rounded-sm border px-1"
				/>
			) : (
				<></>
			)}
		</div>
	);
}
