export default function TopHeader({ name }) {
	function avatarInitials(name) {
		try {
			const arr = name.trim().split(" ");
			if (arr.length === 1) {
				return name.slice(0, 2).toUpperCase();
			} else {
				const initial1 = arr[0][0];
				const initial2 = arr[arr.length - 1][0];
				return (initial1 + initial2).toUpperCase();
			}
		} catch (err) {
			// Default avatar initial if the above condition fails
			return "JD";
		}
	}

	return (
		<div className="flex h-16 w-full flex-row items-center justify-between border-b border-gray-200 bg-white">
			<img src="./Product-logo.svg" alt="Logo" className="ml-4 h-10 shrink-0" />
			<div className="mr-5 flex h-7 w-7 flex-col  items-center justify-center rounded-full bg-blue-100 text-gray-500">
				{avatarInitials(name)}
			</div>
		</div>
	);
}
