import { Route, Routes } from "react-router-dom";
import EnergyPage from "./EnergyPage";
import DrivingFasterMasterDataCreationPage from "./UseCases/DrivinigFasterMasterDataCreation";
import DrivingPaymentCompliancePage from "./UseCases/DrivingPaymentCompliance";

export default function EnergyRoutes() {
	return (
		<Routes>
			<Route path="/energy">
				<Route index element={<EnergyPage />} />
				<Route
					path="01-driving-payment-compliance"
					element={<DrivingPaymentCompliancePage />}
				/>
				<Route
					path="02-driving-faster-master-data-creation"
					element={<DrivingFasterMasterDataCreationPage />}
				/>
			</Route>
		</Routes>
	);
}
