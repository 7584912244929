import { Route, Routes } from "react-router-dom";
import FoodandBeveragePage from "./FoodandBeveragePage";
import OrderManagementPage from "./UseCases/OrderManagementPage";
import DrivingSTPPage from "./UseCases/DrivingSTPPage";

export default function FoodandBeverageRoutes() {
	return (
		<Routes>
			<Route path="/food-and-beverage">
				<Route index element={<FoodandBeveragePage />} />
				<Route path="05-order-management" element={<OrderManagementPage />} />
				<Route path="02-driving-stp" element={<DrivingSTPPage />} />
			</Route>
		</Routes>
	);
}
