import { Route, Routes } from "react-router-dom";
import InsurancePage from "./InsurancePage";
import InsurerPage from "./UseCases/InsurerPage";

export default function InsuranceRoutes() {
	return (
		<Routes>
			<Route path="/insurance">
				<Route index element={<InsurancePage />} />
				<Route path="13-fortune-500-insurer" element={<InsurerPage />} />
			</Route>
		</Routes>
	);
}
