import IframeViewer from "../../../components/IframeViewer";

export default function SCMPostMergerPage() {
	return (
		<IframeViewer
			title={"SCM Post Merger Pharma"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=8efca09d-b983-4a9a-9b01-35bd6c73fe9c&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
