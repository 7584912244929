import IframeViewer from "../../../components/IframeViewer";

export default function OrderManagementPage() {
	return (
		<IframeViewer
			title={"Order Management"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=5c6ca565-b1bd-423e-ac95-61995709aff6&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
