import { Route, Routes } from "react-router-dom";
import UUCPage from "./UUCPage";
import ApplicationOptimizationPage from "./UseCases/ApplicationOptimizationPage";
import COISPage from "./UseCases/COISPage";
import COISBillingPage from "./UseCases/COISBillingPage";
import COISProcurementPage from "./UseCases/COISProcurementPage";
import DataCaptureEfficienyClaimsPage from "./UseCases/DataCaptureEfficienyClaimsPage";
import PlatformSandPage from "./UseCases/PlatformSandPage";
import PlatformOutlookPage from "./UseCases/PlatformOutlookPage";
import PlatformDocumentPage from "./UseCases/PlatformDocumentPage";
import PlatformWorkDynamicsPage from "./UseCases/PlatformWorkDynamicsPage";
import ITUseCasePage from "./UseCases/ITUseCasePage";
import DisconnectionDebtPage from "./UseCases/DisconnectionDebtPage";
import OptimizingDataUtilizationPage from "./UseCases/OptimizingDataUtilizationPage";
import SAPVisibilityAndOptimizationPage from "./UseCases/SAPVisibilityAndOptimizationPage";

export default function UUCRoutes() {
	return (
		<Routes>
			<Route path="/uuc">
				<Route index element={<UUCPage />} />
				<Route
					path="01-uuc-application-optimization"
					element={<ApplicationOptimizationPage />}
				/>
				<Route path="02-uuc-cois" element={<COISPage />} />
				<Route path="03-uuc-cois-billing" element={<COISBillingPage />} />
				<Route
					path="04-uuc-cois-procurement"
					element={<COISProcurementPage />}
				/>
				<Route
					path="05-data-capture-efficiciency-claims"
					element={<DataCaptureEfficienyClaimsPage />}
				/>
				<Route path="06-platform-sand" element={<PlatformSandPage />} />
				<Route path="07-platform-outlook" element={<PlatformOutlookPage />} />
				<Route
					path="08-platform-documents"
					element={<PlatformDocumentPage />}
				/>
				<Route
					path="09-platform-work-dynamics"
					element={<PlatformWorkDynamicsPage />}
				/>
				<Route path="10-it-use-case" element={<ITUseCasePage />} />
				<Route
					path="11-disconnection-debt"
					element={<DisconnectionDebtPage />}
				/>
				<Route
					path="12-optimizing-data-utilization"
					element={<OptimizingDataUtilizationPage />}
				/>
				<Route
					path="13-sap-visibility-and-optimization"
					element={<SAPVisibilityAndOptimizationPage />}
				/>
			</Route>
		</Routes>
	);
}
