import IframeViewer from "../../../components/IframeViewer";

export default function DataCaptureEfficienyClaimsPage() {
	return (
		<IframeViewer
			title={"Optimize Data Capture"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=564d7d14-db92-4722-b39f-9b4885fc54bc&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
