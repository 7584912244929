import IframeViewer from "../../../components/IframeViewer";

export default function InsurerPage() {
	return (
		<IframeViewer
			title={"Fortune 500 Insurer"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=73b10003-be13-42fc-8de7-ce1ac05cdedc&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
