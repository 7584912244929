import { Route, Routes } from "react-router-dom";
import PharmaPage from "./PharmaPage";
import SCMPostMergerPage from "./UseCases/SCMPostMergerPage";

export default function PharmaRoutes() {
	return (
		<Routes>
			<Route path="/pharma">
				<Route index element={<PharmaPage />} />
				<Route
					path="04-scm-post-merger-pharma"
					element={<SCMPostMergerPage />}
				/>
			</Route>
		</Routes>
	);
}
