import IndustryCard from "./IndustryCard";

export default function IndustryCardListing({ cards }) {
	const renderedCards = cards.map((card, index) => {
		return (
			<IndustryCard
				key={index}
				url={card.path}
				icon={card.icon}
				industryName={card.buttonText}
			/>
		);
	});

	return (
		<div
			className="
        grid gap-6 p-6
        lg:grid-cols-3 
        2xl:grid-cols-4 
        3xl:grid-cols-5
    "
		>
			{renderedCards}
		</div>
	);
}
