import Modal from "./Modal";
import expandIcon from "./icons/full-screen.svg";
import { useState } from "react";

export default function ModalCard({ img, tag, title }) {
	const [showModal, setShowModal] = useState(false);

	function handleClose() {
		setShowModal(false);
	}

	const modalContent = (
		<div>
			<img src={img} alt={title} />
		</div>
	);

	const modalActionBar = (
		<div className="flex flex-row">
			<button
				className="text-sm font-bold uppercase text-red-500"
				type="button"
				onClick={handleClose}
			>
				Close
			</button>
		</div>
	);

	return (
		<div className="flex h-96 w-full flex-col rounded border border-gray-1">
			<img
				src={img}
				alt={`${title}`}
				className="left-0 h-52 w-auto rounded rounded-b-none border"
			/>
			<div className="relative flex-1 p-4">
				<p className="inline-flex rounded-sm bg-tint-20 px-1">{tag}</p>
				<p
					className="mt-3 line-clamp-2 px-1 font-semibold"
					data-te-toggle="tooltip"
					data-te-placement="bottom"
					data-te-ripple-init
					data-te-ripple-color="light"
					title={`${title}`}
				>
					{title}
				</p>
				<div
					className="
                absolute bottom-4 flex flex-row gap-2 rounded border border-tint-20 px-4 py-1.5 font-semibold
                hover:cursor-pointer hover:bg-tint-20"
					onClick={() => setShowModal(true)}
				>
					<p>View full screen</p>
					<img src={expandIcon} alt="open in modal" />
				</div>
				{showModal ? (
					<Modal
						title={title}
						modalContent={modalContent}
						actionBar={modalActionBar}
						handleClose={handleClose}
						img={img}
					/>
				) : null}
			</div>
		</div>
	);
}
