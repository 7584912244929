import IframeViewer from "../../../components/IframeViewer";

export default function COISProcurementPage() {
	return (
		<IframeViewer
			title={"Cost of Unintegrated Systems - Procurement"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=fcfa67f3-f881-421b-825a-42eff1cd340e&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
