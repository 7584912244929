import IframeViewer from "../../../components/IframeViewer";

export default function PlatformDocumentPage() {
	return (
		<IframeViewer
			title={"Platform - Document"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=1adb6bba-050e-4866-a3de-a5add3ff2a90&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
