import IframeViewer from "../../../components/IframeViewer";

export default function PlatformWorkDynamicsPage() {
	return (
		<IframeViewer
			title={"Platform - Work Dynamics"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=b9c8f190-8778-46dd-88cc-559a8aa679f8&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
