import IframeViewer from "../../../components/IframeViewer";

export default function PlatformSandPage() {
	return (
		<IframeViewer
			title={"Platform - Sand"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=49fc9d82-1f06-4918-8161-0298976165bb&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
