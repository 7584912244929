import { image1, image3, image5, image6 } from "./BFSIThumnails/index";
import { useContext } from "react";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";
import useSearchBox from "../../hooks/useSearchBox";

export default function BFSIPage() {
	const { accounts } = useContext(MsalContext);

	const cardDetails = [
		{
			id: 1,
			title:
				"Taking Customer Experience to the Next Level in Financial Services",
			tag: "Financial Services",
			coverImage: image1,
			url: "/bfsi/07-wealth-management",
		},
		{
			id: 2,
			title: "Solving Funds Reconciliation in a Leading Financial Institution",
			tag: "Banking & Financial Services",
			coverImage: image3,
			url: "/bfsi/08-reconciliation",
		},
		{
			id: 3,
			title: "Turbocharging Underwriting Function in a Mortgage Firm",
			tag: "Financial Services",
			coverImage: image5,
			url: "/bfsi/11-underwriting-mortgage-firm",
		},
		{
			id: 4,
			title: "Accelerating Customer Onboarding in a Leading Mortgage Firm",
			tag: "Financial Services",
			coverImage: image6,
			url: "/bfsi/12-customer-onboarding-in-mortgage-firm",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Banking and Financial Services" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
