import IframeViewer from "../../../components/IframeViewer";

export default function ITUseCasePage() {
	return (
		<IframeViewer
			title={"IT Use Case"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=4ad0f7ac-4527-4922-a385-d1317cb473e7&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
