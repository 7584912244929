import { image2 } from "./pharmaThumbnails";
import { useContext } from "react";
import { MsalContext } from "@azure/msal-react";
import TopHeader from "../../components/TopHeader";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SuccessStoriesBanner from "../../components/SuccessStoriesBanner";
import UseCaseCardListing from "../../components/UseCaseCardListing";
import useSearchBox from "../../hooks/useSearchBox";

export default function PharmaPage() {
	const { accounts } = useContext(MsalContext);
	const cardDetails = [
		{
			id: 1,
			title: "Driving Post-Merger Transformation in a Global Pharma Company",
			tag: "Supply Chain",
			coverImage: image2,
			url: "/pharma/04-scm-post-merger-pharma",
		},
	];

	const [value, handleChange, getData] = useSearchBox(cardDetails);

	return (
		<div>
			<TopHeader name={accounts[0].name} />
			<BreadCrumb pageName="Pharma" />
			<SuccessStoriesBanner value={value} onChange={handleChange} />
			<UseCaseCardListing cards={getData()} />
		</div>
	);
}
