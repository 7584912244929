import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useState } from "react";
import ManufacturingRoutes from "./pages/Manufacturing/ManufacturingRoutes";
import ConstructionRoutes from "./pages/Construction/ConstructionRoutes";
import PharmaRoutes from "./pages/Pharma/PharmaRoutes";
import FoodandBeverageRoutes from "./pages/FoodandBeverage/FoodandBeverageRoutes";
import BFSIRoutes from "./pages/BFSI/BFSIRoutes";
import InsuranceRoutes from "./pages/Insurance Lending/InsuranceRoutes";
import UUCRoutes from "./pages/UUC/UUCRoutes";
import SelfServiceAnalyticsRoutes from "./pages/SelfServiceAnalytics/SelfServiceAnalyticsRoutes";
import EnergyRoutes from "./pages/Energy/EnergyRoutes";

export default function App() {
	useMsalAuthentication(InteractionType.Redirect);
	const [m_strUser, setm_strUser] = useState("");
	const [name, setName] = useState("");
	function Render() {
		const { accounts } = useMsal();
		try {
			const username = accounts[0].username;
			setm_strUser(username);
			setName(accounts[0].name);
		} catch (e) {}
	}

	if (m_strUser !== "") {
		return (
			<div>
				<Routes>
					<Route index element={<Homepage name={name} />} />
				</Routes>
				<ManufacturingRoutes />
				<ConstructionRoutes />
				<PharmaRoutes />
				<FoodandBeverageRoutes />
				<BFSIRoutes />
				<InsuranceRoutes />
				<UUCRoutes />
				<SelfServiceAnalyticsRoutes />
				<EnergyRoutes />
			</div>
		);
	} else
		return (
			<>
				{Render()}
				<div>Please wait...</div>
			</>
		);
}
