import IframeViewer from "../../../components/IframeViewer";

export default function SAPVisibilityAndOptimizationPage() {
	return (
		<IframeViewer
			title={"SAP : Visibility and Optimization"}
			src={
				"https://app.powerbi.com/reportEmbed?reportId=8597a6ff-5953-478b-a991-95e325f86785&autoAuth=true&ctid=217024cc-23bf-42d2-a7cf-d270166db3e2"
			}
		/>
	);
}
