import ModalCard from "./ModalCard";

export default function ModalCardListing({ cards }) {
	if (cards.length === 0) {
		return <div>No Use Cases Found</div>;
	}
	const renderedCards = cards.map((card) => {
		return (
			<ModalCard
				key={card.id}
				img={card.coverImage}
				tag={card.tag}
				title={card.title}
			/>
		);
	});
	return (
		<div
			className="
        grid grid-cols-1 place-items-center gap-6 p-6  
        sm:grid-cols-2
        lg:grid-cols-3 
        2xl:grid-cols-4
        3xl:grid-cols-5"
		>
			{renderedCards}
		</div>
	);
}
