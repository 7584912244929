import { Route, Routes } from "react-router-dom";
import SelfServiceAnalyticsPage from "./SelfServiceAnalyticsPage";

export default function SelfServiceAnalyticsRoutes() {
	return (
		<Routes>
			<Route path="/ssa">
				<Route index element={<SelfServiceAnalyticsPage />} />
			</Route>
		</Routes>
	);
}
