import { Link } from "react-router-dom";

export default function IndustryCard({ url, icon, industryName }) {
	return (
		<Link to={url}>
			<div
				className="
        relative flex h-40 flex-col items-center justify-center rounded border border-neutral-300 bg-white
        hover:cursor-pointer hover:border-4 hover:border-tint-40"
			>
				<img src={icon} alt={industryName} />
				<p className="mt-3 text-sm font-semibold text-slate-950">
					{industryName}
				</p>
			</div>
		</Link>
	);
}
