import IndustryCardListing from "../../components/IndustryCardListing";
import SecondaryHeader from "../../components/SecondaryHeader";
import TopHeader from "../../components/TopHeader";
import globeImage from "./full-globe-wo-blue.svg";
import manufactingIcon from "./icons/manufacturing.svg";
import constructionIcon from "./icons/Construction.svg";
import pharmaIcon from "./icons/Pharma.svg";
import FoodandBeverageIcon from "./icons/Food-and-Beverage.svg";
import BFSIIcon from "./icons/BFSI.svg";
import InsuranceIcon from "./icons/Insurance.svg";
import uccIcon from "./icons/Universal_use_cases_icon.svg";
import selfServiceIcon from "./icons/self_service.svg";
import energyIcon from "./icons/Energy.svg";

export default function Homepage({ name }) {
	const industryCards = [
		{ buttonText: "BFSI", path: "/bfsi", icon: BFSIIcon },
		{
			buttonText: "Construction",
			path: "/construction",
			icon: constructionIcon,
		},
		{
			buttonText: "Food & Beverage",
			path: "/food-and-beverage",
			icon: FoodandBeverageIcon,
		},
		// { buttonText: "Healthcare", path: "/healthcare" },
		{
			buttonText: "Insurance Lending",
			path: "/insurance",
			icon: InsuranceIcon,
		},
		{
			buttonText: "Manufacturing",
			path: "/manufacturing",
			icon: manufactingIcon,
		},
		{ buttonText: "Pharma", path: "/pharma", icon: pharmaIcon },
		{ buttonText: "Energy", path: "/energy", icon: energyIcon },
		{ buttonText: "Universal Use Cases", path: "/uuc", icon: uccIcon },
		{
			buttonText: "Self Service Analytics",
			path: "/ssa",
			icon: selfServiceIcon,
		},
	];

	return (
		<div>
			<TopHeader name={name} />
			<SecondaryHeader
				globeImage={globeImage}
				primaryText="Scout Platform use cases"
				subText="Industries"
			/>
			<IndustryCardListing cards={industryCards} />
		</div>
	);
}
