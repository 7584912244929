import { createPortal } from "react-dom";

export default function Modal({ title, modalContent, actionBar, handleClose }) {
	const renderModal = (
		<>
			<div
				className="fixed inset-0 h-full w-full overflow-y-auto overflow-x-hidden bg-black opacity-25"
				onClick={handleClose}
			></div>
			<div className="absolute inset-20 flex h-fit flex-col rounded border border-s border-slate-200 bg-white">
				{/* header */}
				<div className="flex flex-row justify-between border-b border-solid border-slate-200 p-5">
					<h3 className="text-3xl font-semibold">{title}</h3>
					<div>{actionBar}</div>
				</div>

				{/* content */}
				<div className="p-5">
					<div>{modalContent}</div>
				</div>

				{/* footer */}
				<div className="flex justify-end   border-t border-solid border-slate-200 p-5">
					<div>{actionBar} </div>
				</div>
			</div>
		</>
	);

	return createPortal(
		renderModal,
		document.getElementById("overlay-container"),
	);
}
